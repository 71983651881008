import { createRouter, createWebHistory } from 'vue-router'
import Ontology from '../views/Ontology.vue';
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Ontology
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/ontology',
    name: 'ontology',
    component: Ontology
  },
  {
    path: '/dashboards/:dashboard_id',
    name: 'Dashboard',
    component: Dashboard,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(!store.getters.isLoggedIn && to.path !== '/login')
    return next('/login');

  if (to.path === '/login' && store.getters.isLoggedIn)
    return next('/'); // Redirect logged-in users away from login
  
  next(); // Allow navigation
});

export default router
