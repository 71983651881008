<template>
  <div view="data-import" view-modal>
    <div class="view-form data-import">
      <div class="form-title">
        <div class="form-column">
          <h4><i class="las la-file-import"></i> Import <i>{{ modalData.table_slug }}</i> data</h4>
        </div>
        <div class="form-column deploy">
          <button class="hollow red-hollow cancel" @click="closeModal">Cancel <i class="las la-times-circle"></i></button>
          <button class="hollow green-hollow" @click="submitCSV">Upload <i class="las la-arrow-circle-right"></i></button>
        </div>
      </div>
      <div class="form-body">
        <hr style="margin-top: 0px;">
        <p class="tip">Choose a <b>.csv</b> file to extract data from into your <b>{{ modalData.table_name }}</b> table.</p>
        <form @submit.prevent="submitCSV">
          <div class="form-row">
            <div class="form-field">
              <input type="file" accept=".csv" @change="handleFileUpload"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    modalData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      csvFile: null
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    upload() {

    },
    closeModal() {
      this.$emit('close-modal');
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === 'text/csv') {
        this.csvFile = file;
      } else {
        alert("Please upload a valid CSV file.");
        this.csvFile = null;
      }
    },
    async submitCSV() {
      if (!this.csvFile) {
        alert("No CSV file selected");
        return;
      }

      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("file_data", this.csvFile);
      formData.append("user_id", this.user.user_id); // Replace with actual user_id
      formData.append("table_slug", this.modalData.table_slug); // Replace with actual table slug if needed

      try {
        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/upload_controller.php', {
          method: 'POST',
          body: formData
        });

        const result = await response.json();

        if (response.ok && result.status == "success") {
          alert(result.message);
          this.$emit('close-modal');
          this.$router.push('/ontology');
        } else {
          alert("Error uploading file: " + result.message);
        }
      } catch (error) {
        alert("Error: " + error.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .form-title {
    h4 {
      font-size: 20px !important;
    }
  }
  .new-table-field {
    margin-bottom: 10px;
  }

  .deploy {
    margin-left: auto;
    margin-right: 15px;
    
    button.cancel {
      margin-right: 15px;
    }
  }
</style>
