<template>
  <div id="updates">
    <h4><span>LTOMS</span> Platform Development Updates</h4>
    <hr>
    <div><b>12-23-2024 (v0.71)</b>
      <ul>
        <li>Added a <u>Bitcoin</u> trade analysis module because o1 is crazy</li>
      </ul>
    </div>
    <div><b>12-20-2024 (v0.70)</b>
      <ul>
        <li>Default theme is now Dark Mode (no light mode implemented yet)</li>
        <li>Styles, colors, and fonts have been updated across the application</li>
        <li>A basic <u>Intercom</u> video chat module has been implemented for testing</li>
        <li>There is now a 'Fill' option for initial dashboard grid layouts</li>
      </ul>
    </div>
    <div><b>12-17-2024 (v0.61)</b>
      <ul>
        <li>Streaming tables have been introduced to the operating system</li>
        <li>IoT Device fields in streamed tables now only show their own fields for selection</li>
        <li>Fixed multiple streaming field name issues with the data preview/ontology elements</li>
        <li>Modules can now be removed from dashboards individually using the "Delete this module" button at the bottom of the configuration pane</li>
        <li>Tables now show their last update timestamp in their tool tip and stream tables have a relevant icon color for their status</li>
        <li>TimeCharts are now segmentable by other fields</li>
        <li>A basic live reload system has been implemented for TimeCharts</li>
      </ul>
    </div>
    <div><b>12-3-2024 (v0.57)</b>
      <ul>
        <li>Fixed an error when loading data tables with no values in them</li>
        <li>Fixed non-full data table pages breaking the CSS</li>
      </ul>
    </div>
    <div><b>12-1-2024 (v0.56)</b>
      <ul>
        <li><b>Data Table</b> modules have been given pagination configuration/control</li>
      </ul>
    </div>
    <div><b>11-26-2024 (v0.55)</b>
      <ul>
        <li><b>Data Table</b> now has some configuration options that persist to the backend. Each chart instance uses their own configuration</li>
        <li><b>Time Chart</b> now has configuration options. Still testing the actual chart displays.</li>
      </ul>
    </div>
    <div><b>11-25-2024 (v0.53)</b>
      <ul>
        <li>The EditTable modal has been added, allowing you to edit already created tables and their fields</li>
        <li>The TimeChart module has been re-enabled, and now loads a test demo for an ApexChart line graph</li>
      </ul>
    </div>
    <div><b>11-23-2024 (v0.51)</b>
      <ul>
        <li>The data tables action menu has been moved to an inline menu, moving it out of the way of other UI elements</li>
        <li>The first in-development module has been added - the <b>DataTable</b> - It's bare bones but still try adding it to a dashboard!</li>
      </ul>
    </div>
    <div><b>11-20-2024 (v0.49)</b>
      <ul>
        <li>Dashboards can now have their main layout configured</li>
        <li>Dashboards are now linked from the router via their ID to prevent duplicate routes.</li>
      </ul>
    </div>
    <div><b>11-18-2024 (v0.47)</b>
      <ul>
        <li>Dashboards now have a delete button in the upper right hand corner</li>
        <li>Dashboard slugification is now handled automatically. 
          <br>- Feel free to use normal naming conventions, i.e. Dashboard 1 instead of dashboard_1, and the system will make the slug for you.</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #updates {
    padding: 5px 10px;
    border: 1px solid gray;
    overflow-y: auto;
    max-height: 300px;

    div {
      margin: 0;

      &:not(:last-child){
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid lighten($app-view-form-color, 10%);
      }
    }

    li {
      font-size: 15px !important;
    }

    hr {
      margin: 0 0 10px 0;
    }

    i {
      font-weight: 800;
      font-size: 15px;
    }

    h4 {
      margin: 5px 0;
      font-weight: 400 !important;

      span {
        color: rgb(73, 137, 206);
        font-weight: 800;
      }
    }
  }
</style>