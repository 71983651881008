<template>
  <nav v-if="isLoggedIn">
    <router-link to="/" class="router-link dashboard">
      <DashboardIcon />
    </router-link>
    <router-link to="/ontology" class="router-link ontology">Ontology</router-link>
    <div v-for="dashboard in dashboards" :key="dashboard.dashboard_id">
      <!-- Conditionally render the slug or an input box -->
      <div v-if="!dashboard.editing" @dblclick="enableEditing(dashboard)" class="--flag-IGNORE-LINK-BORDER">
        <router-link :to="`/dashboards/${dashboard.dashboard_id}`" class="router-link dashboard-link">{{ deslug(dashboard.dashboard_slug) }}</router-link>
      </div>
      <input
        v-else
        type="text"
        class="dashboard-slug-change-input-field"
        :dashboard_id="dashboard.dashboard_id"
        v-model="dashboard.dashboard_slug"
        ref="dashboardSlugChangeInput"
        @blur="updateDashboardSlug(dashboard)"
        @keyup.enter="updateDashboardSlug(dashboard)"
      />
    </div>
    <div id="newDashboard" @click="createNewDashboard">
      <span class="info-icon" tool-tip>
        <div class="tool-tip right">
          <div class="tool-tip-content">
            Add new dashboard
          </div>
        </div>
        <i class="las la-plus"></i>
      </span>
    </div>
    <template v-if="isLoggedIn">
      <div class="nav-menu user">
        <div><i class="las la-bars"></i></div>
        <ul>
          <li>
            <div class="nav-button logout" @click="logout">Logout</div>
          </li>
        </ul>
      </div>
    </template>
  </nav>
</template>

<script>
import { capitalize } from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      dashboards: []
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user'])
  },
  methods: {
    ...mapActions(['logout']),
    deslug(value) {
      if (!value) return '';
      return value.toString()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    slugify(value) {
      if (!value) return '';
      return value.toString()
        .split(' ')
        .map(word => word.toLowerCase())
        .join('_');
    },
    admin() {
      this.$router.push('/admin');
    },
    // Add this method to your navbar component
    async createNewDashboard() {
      if (!this.user || !this.user.user_id) return;

      try {
        const params = JSON.stringify({
          user_id: this.user.user_id,
          dashboard_slug: 'dashboard'  // This will create dashboard_1, dashboard_2, etc.
        });

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/app/controllers/dashboard_controller.php?controller=dashboard&method=create&params=${encodeURIComponent(params)}`,
          { method: 'GET' }
        );

        const result = await response.json();

        if (result.status === 'success') {
          // Add new dashboard to list
          this.dashboards.push(result.dashboard);
          // Navigate to new dashboard
          this.$router.push(`/dashboards/${result.dashboard.dashboard_id}`);
        } else {
          console.error('Failed to create dashboard:', result.message);
        }
      } catch (error) {
        console.error('Error creating dashboard:', error);
      }
    },
    async fetchDashboards() {
      if (!this.user || !this.user.user_id) {
        console.log('User not yet loaded');
        return;
      }

      try {
        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/dashboard_controller.php?controller=dashboard&method=read&params={"user_id":"'+this.user.user_id+'"}', {
          method: 'GET'
        });

        const result = await response.json();
        console.log('Dashboard response:', result); // Debug response

        if (result.status === 'success') {
          this.dashboards = result.dashboards;
        } else {
          console.error('Failed to load dashboards:', result.message);
        }
      } catch (error) {
        console.error("Failed to load dashboards - error:", error);
      }
    },
    enableEditing(dashboard) {
      dashboard.editing = true;

      this.$nextTick(() => {
        const currentVal = document.querySelector('input[type=text].dashboard-slug-change-input-field[dashboard_id="'+dashboard.dashboard_id+'"]').value;
        document.querySelector('input[type=text].dashboard-slug-change-input-field[dashboard_id="'+dashboard.dashboard_id+'"]').value = this.deslug(currentVal);
        document.querySelector('input[type=text].dashboard-slug-change-input-field[dashboard_id="'+dashboard.dashboard_id+'"]').select();
      });
    },
    async updateDashboardSlug(dashboard) {
      dashboard.editing = false;

      try {
        const params = {
          dashboard_id: dashboard.dashboard_id,
          dashboard_slug: dashboard.dashboard_slug,
        };

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/app/controllers/dashboard_controller.php`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // Specify the data format
            },
            body: `controller=dashboard&method=update_slug&params=`+JSON.stringify(params), // Send params as JSON
          }
        );

        const result = await response.json();

        if (result.status !== 'success') {
          console.error('Failed to update dashboard:', result.message);
        } else {
          // Re-push the updated dashboard slug as the new current route
          this.$router.push(`/dashboards/${dashboard.dashboard_id}`);
        }
      } catch (error) {
        console.error('Error updating dashboard:', error);
      }
    },
  },
  watch: {
    // Watch for changes to the user object
    user: {
      immediate: true, // This will run on component creation
      handler(newUser) {
        if (newUser && newUser.user_id) {
          this.fetchDashboards();
        }
      }
    }
  },
  mounted() {
    // Still try to fetch on mount in case user is already loaded
    if (this.user && this.user.user_id) {
      this.fetchDashboards();
    }
  }
};
</script>

<style lang="scss">
  nav {
    display: flex;
    justify-content: left;
    height: $navbar-height;
    background: $app-background-color;

    .dashboard-slug-change-input-field {
      display: block;
      height: 40px;
      font-size:  14px;
      border: none;
      width: auto;
      padding: 0 10px;
      background-color: #ececec;

      &:focus {
        outline: none;
        border: none;
      }
    }

    #newDashboard {
      display: flex;
      margin: 10px;
      width: 20px;
      font-weight: 400;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px dashed lighten($app-background-color, 15%);
      
      span, &:hover, i {
        font-size: 13px !important;
        cursor: pointer !important;
      }
      
      i {
        color: rgb(183, 183, 183);
      }

      &:hover {
        background-color: lighten($app-background-color, 5%);
      }
    }
    
    a, .nav-button, .nav-menu {
      display: inline-block;
      font-weight: bold;
      height: calc($navbar-height - $navbar-active-highlight-height);
      line-height: calc($navbar-height - $navbar-active-highlight-height);
      color: $app-text-color;
      text-decoration: none;
      font-weight: 400;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: darken($app-background-color, 2%);
      }

      &.router-link:not(.--flag-IGNORE-LINK-BORDER) {
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }

      &.router-link-exact-active:not(.--flag-IGNORE-LINK-BORDER) {
        color: $ltoms-blue;
        border-bottom: 2px solid $ltoms-blue;
      }

      &.router-link.login, &.logout, &.user {
        margin-left: auto;
      }

      &.user {
        font-weight: 500;
        font-size: 16px;
      }

      &.nav-button {
        font-weight: 400 !important;
      }
    }

    .nav-menu {
      position: relative;
      overflow: hidden;
      padding: 0px;
      height: $navbar-height;
      line-height: $navbar-height;
      z-index: 10;

      ul {
        height: 0px;
        margin: 0;
        padding: 0;
        background: lighten($app-background-color, 10%);
        list-style-type: none;
        padding: 0px;

        li {
          line-height: normal;

          .nav-button {
            font-size: 14px !important;
          }

          &:hover {
            &, .nav-button {
              background-color: darken($app-background-color, 3%);
            }
          }
        }
      }

      &:hover {
        overflow: visible;
        
        ul {
          height: auto !important;
        }
      }
    }
  }
</style>