<template>
  <div view="dashboard" class="dashboard-container">
    <div class="view-panel full">
      <div v-if="loading" class="loading">Loading dashboard...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else class="dashboard">
        <div class="dashboard-row">
          <div class="dashboard-column">
            <h3 class="dashboard-title">{{ deslug(dashboard.dashboard_slug) }}</h3>
            <div class="dashboard-modes">
              <div v-if="locked" class="dashboard-mode locked" @click="unlockDashboard">
                <span class="info-icon" tool-tip>
                  <div class="tool-tip right">
                    <div class="tool-tip-content">
                      View Only Mode <small>(Click to Enable Editing)</small>
                    </div>
                  </div>
                  <i class='las la-eye'></i>
                </span>
              </div>
              <div v-if="!locked" class="dashboard-mode unlocked" @click="lockDashboard">
                <span class="info-icon" tool-tip>
                  <div class="tool-tip right">
                    <div class="tool-tip-content">
                      Editing Enabled <small>(Click to Disable)</small>
                    </div>
                  </div>
                  <i class='las la-unlock'></i>
                </span>
              </div>
            </div>
          </div>
          <div class="dashboard-column"></div>
          <div v-if="!locked" class="dashboard-column dashboard-delete">
            <button class="hollow red-hollow" @click="deleteDashboard">
              Delete <i class="las la-times"></i>
            </button>
          </div>
        </div>
        <!-- Grid section  -->
        <div class="dashboard-row fill">
          <template v-if="grid === null">
            Loading ...
          </template>
          <template v-else>
            <template v-if="!locked">
              <div class="dashboard-edit-grid">
                <template v-if="Object.keys(grid).length == 0">
                  <!-- Empty grid layout selection -->
                  <div id="emptyEditGrid">
                    <div class="message">
                      <h2>Set a layout for this dashboard</h2>
                      <div class="dashboard-layout-options">
                        <div class="dashboard-layout-option" @click="addLayout('fill')">
                          <i class="las la-expand-arrows-alt"></i> Fill
                        </div>
                        <div class="dashboard-layout-option" @click="addLayout('rows')">
                          <i class="las la-bars"></i> Rows
                        </div>
                        <div class="dashboard-layout-option" @click="addLayout('columns')">
                          <i class="las la-columns"></i> Columns
                        </div>
                        <div class="dashboard-layout-option" @click="addLayout('grid')">
                          <i class="las la-border-all"></i> Grid
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                
                <!-- Grid with content -->
                <template v-else>
                  <div class="grid-container editing">
                    <div v-for="(row, rowIndex) in grid.rows" :key="rowIndex" class="grid-row">
                      <template v-if="row.columns">
                        <div v-for="(column, colIndex) in row.columns" :key="colIndex" class="grid-column">
                          <div v-if="column && column.module? column.module: null" class="content-box">
                            <Suspense>
                              <template #default>
                                <AsyncModuleWrapper>
                                  <DataTable 
                                    v-if="getModuleType(column) === 'data_table'"
                                    @save-module="saveDashboard"
                                    @remove-module="removeModule"
                                    :moduleSettings="getModuleSettings(column)"
                                    :editMode="!locked"
                                    :rowIndex="rowIndex"
                                    :colIndex="colIndex"
                                  />
                                  <TimeChart 
                                    v-if="getModuleType(column) === 'time_chart'"
                                    @save-module="saveDashboard"
                                    @remove-module="removeModule"
                                    :moduleSettings="getModuleSettings(column)"
                                    :editMode="!locked"
                                    :rowIndex="rowIndex"
                                    :colIndex="colIndex"
                                  />
                                  <Intercom 
                                    v-if="getModuleType(column) === 'intercom'"
                                    @save-module="saveDashboard"
                                    @remove-module="removeModule"
                                    :moduleSettings="getModuleSettings(column)"
                                    :editMode="!locked"
                                    :rowIndex="rowIndex"
                                    :colIndex="colIndex"
                                  />
                                  <Bitcoin 
                                    v-if="getModuleType(column) === 'bitcoin'"
                                    @save-module="saveDashboard"
                                    @remove-module="removeModule"
                                    :moduleSettings="getModuleSettings(column)"
                                    :editMode="!locked"
                                    :rowIndex="rowIndex"
                                    :colIndex="colIndex"
                                  />
                                </AsyncModuleWrapper>
                              </template>
                              <template #fallback>
                                <div class="loading-state">
                                  <i class="las la-spinner la-spin"></i> Loading...
                                </div>
                              </template>
                            </Suspense>
                          </div>
                          <div v-else class="content-box">
                            <select class="dashboard-module-select" @change="addModule($event.target.value, rowIndex, colIndex)">
                              <option value="" disabled selected hidden>Add a Module</option>
                              <option value="data_table">Data Table</option>
                              <option value="time_chart">Time Chart</option>
                              <option value="intercom">Intercom</option>
                              <option value="bitcoin">Bitcoin</option>
                            </select>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="content-box">
                          <select class="dashboard-module-select" @change="addModule($event.target.value, rowIndex)">
                            <option value="" disabled selected hidden>Add a Module</option>
                            <option value="data_table">Data Table</option>
                            <option value="time_chart">Time Chart</option>
                            <option value="intercom">Intercom</option>
                            <option value="bitcoin">Bitcoin</option>
                          </select>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              
              <!-- View mode -->
              <div v-if="Object.keys(grid).length == 0" id="emptyGrid">
                <div class="message">
                  <h2>Your Dashboard is Empty</h2>
                  <p>Unlock your dashboard by clicking the <i class="las la-eye"></i> button</p>
                  <p>Add modules and they will appear here when not editing</p>
                </div>
              </div>
              <div v-else class="grid-container">
                <div v-for="(row, rowIndex) in grid.rows" :key="rowIndex" class="grid-row">
                  <template v-if="row.columns">
                    <div v-for="(column, colIndex) in row.columns" :key="colIndex" class="grid-column">
                      <div v-if="column && column.module? column.module: null" class="content-box">
                        <Suspense>
                          <template #default>
                            <AsyncModuleWrapper>
                              <DataTable 
                                v-if="getModuleType(column) === 'data_table'"
                                @save-module="saveDashboard"
                                @update-data-source="updateModuleDataSource"
                                :moduleSettings="getModuleSettings(column)"
                                :editMode="!locked"
                              />
                              <TimeChart 
                                v-if="getModuleType(column) === 'time_chart'"
                                @save-module="saveDashboard"
                                @update-data-source="updateModuleDataSource"
                                :moduleSettings="getModuleSettings(column)"
                                :editMode="!locked"
                                :liveRefresh="getModuleSetting(column.module, 'live_refresh_enabled')"
                              />
                              <Intercom 
                                v-if="getModuleType(column) === 'intercom'"
                                @save-module="saveDashboard"
                                @update-data-source="updateModuleDataSource"
                                :moduleSettings="getModuleSettings(column)"
                                :editMode="!locked"
                              />
                              <Bitcoin 
                                v-if="getModuleType(column) === 'bitcoin'"
                                @save-module="saveDashboard"
                                @update-data-source="updateModuleDataSource"
                                :moduleSettings="getModuleSettings(column)"
                                :editMode="!locked"
                              />
                            </AsyncModuleWrapper>
                          </template>
                          <template #fallback>
                            <div class="loading-state">
                              <i class="las la-spinner la-spin"></i> Loading...
                            </div>
                          </template>
                        </Suspense>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { reactive, defineAsyncComponent } from 'vue';
import Intercom from '@/components/modules/Intercom.vue';

export default {
  name: 'Dashboard',
  props: {
    dashboard_id: {
      type: String,
      required: true
    }
  },
  components: {
    DataTable: defineAsyncComponent(() => 
      import('@/components/modules/DataTable.vue')
        .catch(err => {
          console.error('Failed to load DataTable:', err);
          return { 
            template: '<div>Failed to load component</div>' 
          };
        })
    ),
    TimeChart: defineAsyncComponent(() =>
      import('@/components/modules/TimeChart.vue')
        .catch(err => {
          console.error('Failed to load TimeChart:', err);
          return {
            template: '<div>Failed to load component</div>'
          };
        })
    ),
    Intercom: defineAsyncComponent(() =>
      import('@/components/modules/Intercom.vue')
        .catch(err => {
          console.error('Failed to load Intercom:', err);
          return {
            template: '<div>Failed to load component</div>'
          };
        })
    ),
    Bitcoin: defineAsyncComponent(() =>
      import('@/components/modules/Bitcoin.vue')
        .catch(err => {
          console.error('Failed to load Bitcoin:', err);
          return {
            template: '<div>Failed to load component</div>'
          };
        })
    ),
  },
  data() {
    return {
      dashboard: null,
      locked: true,
      loading: true,
      error: null,
      grid: null
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    getModuleType(column) {
      return column && column?.module?.module_type ? column.module.module_type: null;
    },

    getModuleSettings(column) {
      return column && column?.module?.module_settings ? column.module.module_settings: null;
    },

    getModuleSetting(module, setting) {
      return module?.module_settings?.[setting] ? module.module_settings[setting]: false;
    },

    makeReactive(obj) {
      return reactive(JSON.parse(JSON.stringify(obj)));
    },

    updateModuleDataSource(dataSourceId, rowIndex, colIndex) {
      if (!this.grid.rows[rowIndex].columns[colIndex].module) {
        console.error('No module found at specified position');
        return;
      }

      this.grid.rows[rowIndex].columns[colIndex].module.module_settings.module_data_source = dataSourceId;
      this.saveDashboard();
    },

    addModule(type, rowIndex, colIndex = 0) {
      if (!this.grid.rows[rowIndex].columns) {
        this.grid.rows[rowIndex].columns = [];
      }

      let typeSpecificSettings = {};

      if (type === 'data_table') {
        typeSpecificSettings = {
          module_name: "New Data Table",
          show_module_name: true,
          show_table_preview_info: true,
          show_column_headers: true,
          show_row_index: true,
          column_visibility: {},
          column_sort: {},
          module_data_source: null,
          pagination: {
            enabled: false,
            page_size: 50,
            current_page: 1,
            show_page_controls: true
          }
        }
      } else if (type === 'time_chart') {
        typeSpecificSettings = {
          module_name: "New Time Chart",
          show_module_name: true,
          module_data_source: null,
          x_axis_field: null,
          x_axis_label: "Time",
          date_format: "yyyy-MM-dd",
          y_axis_fields: [],
          y_axis_label: "Values",
          y_axis_min: null,
          y_axis_max: null,
          show_grid: true,
          show_tooltip: true,
          show_legend: true,
          line_colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
          curved_lines: true,
          segment_field: null,
          live_refresh_enabled: false,
          live_refresh_interval: 5000,
        }
      }

      this.grid.rows[rowIndex].columns[colIndex] = {
        module: {
          module_type: type,
          module_settings: {
            ...typeSpecificSettings
          }
        }
      };

      this.saveDashboard();
    },
    // Remove module from the grid
    removeModule(rowIndex, colIndex) {
      this.grid.rows[rowIndex].columns[colIndex].module = null;
      this.saveDashboard();
    },
    // Updated addLayout method
    addLayout(type) {
      let newGrid;

      if(type === 'fill'){
        newGrid = { rows: [{}] };
      } else if(type === 'rows') {
        newGrid = { rows: [{}, {}] };
      } else if(type === 'columns') {
        newGrid = { rows: [{columns: [{}, {}]}] };
      } else if(type === 'grid') {
        newGrid = { rows: [{columns: [{}, {}]}, {columns: [{}, {}]}] };
      }
      
      this.grid = this.makeReactive(newGrid);
      this.saveDashboard();
    },

    // Updated loadDashboard method
    async loadDashboard() {
      this.loading = true;
      this.error = null;
      
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/app/controllers/dashboard_controller.php?controller=dashboard&method=read_dynamic&params={"dashboard_id":"${this.dashboard_id}", "user_id":"${this.user.user_id}"}`,
          {
            method: 'GET'
          }
        );

        const result = await response.json();

        if (result.status === 'success') {
          this.dashboard = result.dashboard;
          if (result.dashboard.dashboard_json) {
            const parsedGrid = JSON.parse(result.dashboard.dashboard_json);
            this.grid = this.makeReactive(parsedGrid);
          }
        } else {
          this.error = result.message || 'Failed to load dashboard';
        }
      } catch (error) {
        console.error('Error loading dashboard:', error);
        this.error = 'Failed to load dashboard data';
      } finally {
        this.loading = false;
      }
    },

    deslug(value) {
      if (!value) return '';
      return value.toString()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    async deleteDashboard() {
      if(!confirm('Are you sure you want to delete this dashboard? This action is irreversible, and all associated data will be permanently deleted.')) {
        return;
      }

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/app/controllers/dashboard_controller.php?controller=dashboard`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `controller=dashboard&method=delete&params={"dashboard_id":"${this.dashboard_id}"}`,
          }
        );
        const result = await response.json();

        if (result.status === 'success') {
          window.location.reload();
        } else {
          console.error('Failed to delete dashboard:', result.message);
        }
      } catch (error) {
        console.error('Error deleting dashboard:', error);
      }
    },
    async saveDashboard() {
      const dashboard_json = JSON.stringify(this.grid);
      const dashboard_slug = this.dashboard.dashboard_slug;
      const dashboard_id = this.dashboard.dashboard_id;
      const user_id = this.user.user_id;

      const payload = {
        dashboard_id: dashboard_id,
        dashboard_slug: dashboard_slug,
        dashboard_json: dashboard_json,
        user_id: user_id
      }

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/app/controllers/dashboard_controller.php`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `controller=dashboard&method=update&params=`+encodeURIComponent(JSON.stringify(payload)),
          }
        );
        const result = await response.json();

        if (result.status === 'success') {
          // Do nothing (silent update)
        } else {
          console.error('Failed to update dashboard:', result.message);
        }
      } catch (error) {
        console.error('Error updating dashboard:', error);
      }
    },

    lockDashboard() {
      this.locked = true;
    },

    unlockDashboard() {
      this.locked = false;
    }
  },
  watch: {
    slug: {
      immediate: true,
      handler() {
        this.loadDashboard(this.dashboard_id);
      }
    },
    dashboard_id: {
      immediate: true,
      handler() {
        this.loadDashboard(this.dashboard_id);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  padding: 20px;

  .grid-container {
    &.editing { padding: 20px; }
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    
    .grid-row {
      display: flex;
      flex: 1;
      margin-bottom: 20px;
      max-height: 100%;
      max-width: 100%;
      overflow: hidden;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .grid-column {
        display: flex;
        flex: 1;
        margin-right: 20px;
        
        &:last-child {
          margin-right: 0;
        }
      }
      
      .content-box {
        position: relative;
        border: 1px solid lighten($app-view-form-color, 10%);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease;
        flex: 1;

        asyncmodulewrapper {
          position: relative;
          @include flex-row;
          overflow: hidden;
          height: 100%;
        }
      }
    }
  }

  .loading {
    text-align: center;
    padding: 20px;
    color: #666;
  }

  .error {
    color: #ff4444;
    padding: 20px;
    text-align: center;
  }

  .dashboard-edit-grid {
    display: flex;
    border: 1px dashed lighten($app-view-form-color, 10%);
    border-radius: 3px;
    width: 100%;

    .dashboard-layout-options {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: 10px;
      border-radius: 3px;
      width: 60vw;

      .dashboard-layout-option {
        flex: 1;
        padding: 10px;
        border-radius: 3px;
        font-size: 20px;
        border: 1px solid rgba(37, 83, 132, 0);

        &:hover {
          background: rgb(27, 60, 95);
          border: 1px solid rgba(37, 83, 132, 1);
          cursor: pointer;
        }
      }
    }

    #emptyEditGrid {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      div.message {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h2 {
          font-weight: 400 !important;
        }
      }
    }

    .dashboard-module-select {
      background: rgb(37, 83, 132);
      outline: none;
      border: none;
      padding: 5px;
      cursor: pointer;

      &, option {
        color: #fefefe;
        border-radius: 3px;
      }
    }
  }

  .dashboard {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    height: 100%;
    overflow: hidden;

    #emptyGrid {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;

      div.message {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        p {
          margin: 0 0 5px 0;
        }

        h2 {
          font-weight: 400 !important;
        }
      }
    }

    .dashboard-row {
      display: flex;
      flex: 0;
      justify-content: space-between;
      padding: 10px 0;
      margin: 0 10px;
      
      &.fill {
        flex: 1;
        margin-bottom: 0;
        max-height: 100%;
        overflow: hidden;
      }

      &:not(.fill){ border-bottom: 1px solid lighten($app-view-form-color, 20%); }
    }

    .dashboard-column {
      display: flex;
      text-align: left;
      flex: 1;

      &.dashboard-delete {
        text-align: right;
        justify-content: right;
        align-items: center;
      }

      .dashboard-title {
        display: inline-block;
        padding: 5px 10px;
        margin: 0;
        border: 1px solid lighten($app-view-form-color, 20%);
        border-radius: 3px;
        font-weight: 500;
      }

      .dashboard-modes {
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;

        .dashboard-mode {
          span.info-icon {
            display: block;
            height: 25px;
          }

          i.las {
            font-size: 25px !important;
            transform: rotateY(180deg);

            &:hover {
              cursor: pointer !important;
            }
          }

          background: #ebebeb00;
          margin: 0;
          border: 1px solid #dbdbdb00;
          padding: 1px 4px;
          margin-left: 3px;
          color:#bebebe;

          &.locked {
            color:#bebebe;
          }

          &.unlocked {
            color: rgb(67, 255, 114);
          }

          &:hover {
            color:darken( #bebebe, 25%);
          }
        }
      }
    }
  }
}
</style>