<template>
  <div id="dataPreview">
    <div class="dp-container">
      <div class="dp-header">
        <div v-if="data.title" class="dp-header-title">
          <span class="dp-action-angle" @click="toggleDataPreviewMinimization"><i class="las la-angle-down"></i></span>
          <span class="dp-header-title-table-name">{{ data.title }}</span>
          - {{ tablePreviewInfo(data) }}
        </div>
      </div>
      <div v-if="data.title" class="dp-grid">
        <div class="dp-column dp-index-column">
          <div class="dp-column-row">0</div>
          <div v-for="(row, index) in data.rows[data.columns[0]]" class="dp-column-row">
            {{ index + 1 }}
          </div>
        </div>
        <div v-for="(column, index) in data.columns" class="dp-column">
          <div class="dp-column-header">
            {{ column }}
          </div>
          <div v-for="row in data.rows[column]" class="dp-column-row">
            {{ row }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Define the prop to accept the JSON object
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleDataPreviewMinimization() {
      const dataPreview = document.querySelector("#dataPreview");
      const dataPreviewActionAngle = document.querySelector("#dataPreview .dp-action-angle");
      const actionAngle = dataPreviewActionAngle.children[0].classList[1];
      const nextAngle = actionAngle == "la-angle-down"? "la-angle-up": "la-angle-down";
      
      if(nextAngle == "la-angle-up") {
        dataPreview.style.maxHeight = "28px";
        dataPreview.querySelector(".dp-grid").style.display = "none";
      } else if(nextAngle == "la-angle-down"){
        dataPreview.style.maxHeight = "400px";
        dataPreview.querySelector(".dp-grid").style.display = "flex";
      }

      dataPreviewActionAngle.children[0].classList.replace(actionAngle, nextAngle);
    },
    tablePreviewInfo(data){
      let rows = "";
      let fields = "";

      if(data !== null && data.hasOwnProperty("rows") && data.hasOwnProperty("columns")){
        if(Object.keys(data.rows) === undefined || data.rows[data.columns[0]] === undefined){
          rows = "0 rows";
        } else {
          rows = data.rows[data.columns[0]].length+" row"+(data.rows[data.columns[0]].length == 1 ? '': 's');
        }

        if(data.columns.length){
          fields = " | "+(data.columns.length)+" field"+(data.columns.length == 1 ? '': 's');
        } else {
          fields = " | 0 columns";
        }

        return rows + fields;
      }
    }
  }
};
</script>

<style lang="scss">
#dataPreview {
  @include flex-row;
  background: lighten($app-view-form-color, 18%);
  border-top: 1px solid lighten($app-view-form-color, 18%);
  border-left: 1px solid lighten($app-view-form-color, 18%);
  border-bottom: 1px solid lighten($app-view-form-color, 18%);
  max-height: 400px;
  overflow-y: none;

  .dp-container {
    @include flex-column;
    max-width: 100%;

    .dp-header {
      @include flex-row;

      flex: 0 !important;
      width: 100%;
      font-size: 14px;

      .dp-header-title {
        flex: 1;
        padding: 5px;
        background: darken($app-view-form-color, 5%);
        text-align: left;
        border-bottom: 1px solid lighten($app-view-form-color, 18%);
        border-right: 1px solid lighten($app-view-form-color, 18%);
        color: lighten($app-text-color, 35%);

        .dp-action-angle {
          cursor: pointer;
        }
        
        i.las[class*="la-angle-"] {
          margin-right: 4px;
        }

        .dp-header-title-table-name {
          border-left: 1px solid lighten($app-view-form-color, 18%);
          padding-left: 10px;
        }

        span {
          color: $app-text-color;
          padding: 0 3px;
        }
      }
    }

    .dp-grid {
      @include flex-row;
      overflow-y: auto;
      border-right: 1px solid lighten($app-view-form-color, 18%);

      .dp-column {
        @include flex-column;

        .dp-column-header {
          border-bottom: 1px solid lighten($app-view-form-color, 18%);
          background: lighten($app-view-form-color, 6%);
          color: #fefefe;
        }

        .dp-column-row {
          border-bottom: 1px solid lighten($app-view-form-color, 18%);
          background: darken($app-view-form-color, 3%);
          white-space: pre;
          font-weight: 300;
        }

        .dp-column-header {
          font-weight: 600;
        }

        .dp-column-header,.dp-column-row {
          padding: 5px 10px;
          font-size: 14px;
          line-height: 14px;
          text-align: left;
        }

        &.dp-index-column {
          flex: 0 !important;

          .dp-column-row {
            text-align: center !important;
            padding: 5px 2px;
            min-width: 25px;
            background: lighten($app-view-form-color, 3%);

            &:first-of-type {
              color: transparent;
              user-select: none;
              pointer-events: none;
            }
          }
        }

        &:not(:first-child){
          border-left: 1px solid lighten($app-view-form-color, 18%);
        }
      }
    }
  }
}
</style>


