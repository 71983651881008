<template>
  <div view="create-table" view-modal>
    <div class="view-form create-table">
      <div class="form-title">
        <div class="form-column">
          <h4><i class="las la-list"></i> Create Table</h4>
        </div>
        <div class="form-column deploy">
          <button class="hollow red-hollow cancel" @click="closeModal">Cancel <i class="las la-times-circle"></i></button>
          <button class="hollow" @click="deploy">Deploy <i class="las la-arrow-circle-right"></i></button>
        </div>
      </div>
      <div class="form-body">
        <hr style="margin-top: 0px;">
        <p class="tip">This table will be available for use in storing, editing, viewing, and transforming your custom business information and data.</p>
        <div class="form-row mg-b-15">
          <div class="form-field">
            <label>Table Name</label>
            <input v-model="table_name"/>
          </div>
          <div class="form-field">
            <label>Table Slug <small>(Read-only)</small></label>
            <input v-model="table_slug" readonly/>
          </div>
          <div class="form-field table-type">
            <label>Table Type</label>
            <select v-model="table_type">
              <option value="" disabled>Select Table Type</option>
              <option value="batch">Batch Data</option>
              <option value="stream">Streamed Data</option>
            </select>
          </div>
        </div>
        <div class="form-row mg-b-0">
          <p class="tip">A primary key field will be automatically generated for this table using the format "{table_slug}_id"</p>
        </div>
        <hr>
        <template v-if="table_type == 'batch'">
          <div class="form-row">
            <div class="form-field add-field mg-b-15">
              <button class="icon" @click="addField">Add Field <i class="las la-plus"></i></button>
            </div>
          </div>
          <div v-for="(field, index) in table_fields" :key="index" class="form-row new-table-field">
            <div class="form-field">
              <button class="icon red" @click="removeField(index)"><i class="las la-ban"></i></button>
            </div>
            <div class="form-field">
              <select v-model="field.type">
                <option value="string">String</option>
                <option value="int">Integer</option>
                <option value="float">Float</option>
                <option value="boolean">Boolean</option>
                <option value="date">Date</option>
                <option value="datetime">Datetime</option>
                <option value="time">Time</option>
                <option value="json">JSON</option>
              </select>
            </div>
            <div class="form-field">
              <input v-model="field.slug" placeholder="Field Slug (ex: user_id)"/>
            </div>
            <div class="form-field">
              <input v-model="field.value" placeholder="Default Value (ex: 1)"/>
            </div>
            <div class="form-field checkbox">
              <input type="checkbox" v-model="field.is_title_field"/>
              <label class="checkbox-label"><small>Title Field</small></label>
            </div>
          </div>
        </template>
        <template v-if="table_type == 'stream'">
          <div class="form-row">
            <div class="form-field">
              <label>Data Source Type</label>
              <select v-model="table_stream_data_source_type" @change="setPrivateKey">
                <option value="" disabled>Select Data Source Type</option>
                <option value="iot">IoT Device(s)</option>
              </select>
            </div>
            <div v-if="table_stream_data_source_type == 'iot'" class="form-field private-key">
              <label>Private Key 
                <i v-if="copiedMessage" style="color: green;font-weight:600;">{{ copiedMessage }}</i>
                <i v-else><small>(Click to copy)</small></i>
              </label>
              <input v-model="table_stream_data_source_private_key" @click="copyKey" readonly/>
            </div>
          </div>
        </template>
        <template v-if="table_type == ''">
          <div class="form-column">
            <div class="form-field">
              <span style="margin-bottom: 5px;">Please select a table type.</span>
              <hr style="width: 40px;margin: 10px auto;">
              <p class="new-table-type"><u>Streamed</u> data comes from sources like IoT sensors and webhooks</p>
              <p class="new-table-type"><u>Batched</u> data is uploaded manually from the platform, or routinely on a scheduled process</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      table_name: '',
      table_slug: '',
      table_type: '',
      table_stream_data_source_type: '',
      table_stream_data_source_private_key: '',
      copiedMessage: null,
      table_fields: [
        { type: 'string', iot_device_id: '', iot_device_field: '', slug: '', value: '', is_title_field: ''}
      ]
    };
  },
  watch: {
    // Watch the first input and update the second input with a formatted version
    table_name(name) {
      this.table_slug = this.tableSlug(name);
    },
    table_fields: {
      deep: true,
      handler(fields) {
        for(let field of fields)
          field.slug = field.slug.split(' ').join('_').toLowerCase();
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['login']),
    copyKey(event){
      const text = event.target.value; // Get the input's value
      navigator.clipboard.writeText(text)
        .then(() => {
          this.copiedMessage = "Copied to clipboard!";
          setTimeout(() => (this.copiedMessage = null), 2000); // Clear message after 2 seconds
        })
        .catch(err => {
          console.error("Failed to copy text:", err);
          this.copiedMessage = "Failed to copy text.";
        });
    },
    async setPrivateKey(){
      const key = await this.NONSECURE_single_check_generateRandomAlphaNumeric(32);
      this.table_stream_data_source_private_key = key;
    },
    async NONSECURE_single_check_generateRandomAlphaNumeric(length=32) {
      
      // Geenerate a random alpha numeric string
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      // Use the alpha numeric string as the private key
      // Check if the private key already exists in the database
      try {
        const payload = {
          private_key: result
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/sensor_controller.php?controller=sensor&method=keys&params='+JSON.stringify(payload), {
          method: 'GET'
        })
        .then(response => {
          const data = response.json();

          if (data.status == 'success') {
            // If it does not exist, return the private key
            return data.key;
          }

          let new_result = '';
          for (let i = 0; i < length; i++) {
            new_result += characters.charAt(Math.floor(Math.random() * characters.length));
          }

          return new_result;
        })
        .catch(error => console.error(error));

        return response;
      } catch (error) {
        console.error(error);
      }
    },
    tableSlug(name) {
      return name.split(' ').join('_').toLowerCase(); // Transform the input to uppercase
    },
    addField(){
      this.table_fields.push({ type: 'string', iot_device_id: '', iot_device_field: '', slug: '', value: '', is_title_field: ''})
    },
    removeField(index){
      if(this.table_fields.length > 1){
        this.table_fields.splice(index, 1);
      }
    },
    async deploy(){
      try {
        const payload = {
          table_name: this.table_name,
          table_slug: this.table_slug,
          table_type: this.table_type,
          table_stream_data_source_type: this.table_stream_data_source_type,
          table_stream_data_source_private_key: this.table_stream_data_source_private_key,
          table_fields: this.table_fields,
          user: this.user
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=create&params=`+JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.$router.push('/ontology');
          this.closeModal(); 
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed table deploy - error: "+error.message);
      }
    },
    closeModal(){
      this.$emit('close-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
  .new-table-type {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .new-table-field {
    margin-bottom: 10px;
  }

  .form-field.private-key {
    flex: 1 !important;

    input {
      font-size: 10px !important;
      line-height: 16px !important;
      cursor: pointer !important;
    }
  }

  .form-field.add-field {
    button {
      width: 80px;
      font-size: 12px !important;
      padding: 5px;
    }
  }

  .mg-b-15 {
    margin-bottom: 15px;
  }

  .mg-b-0 {
    p {
      margin-bottom: 0 !important;
    }
  }

  .deploy {
    margin-left: auto;
    margin-right: 15px;
    
    button.cancel {
      margin-right: 15px;
    }
  }

</style>
