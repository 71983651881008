<template>
  <div view="edit-table" view-modal>
    <div class="view-form edit-table">
      <div class="form-title">
        <div class="form-column">
          <h4><i class="las la-list"></i> Edit Table</h4>
        </div>
        <div class="form-column save">
          <button class="hollow red-hollow cancel" @click="closeModal">Cancel <i class="las la-times-circle"></i></button>
          <button class="hollow" @click="save">Save <i class="las la-save"></i></button>
        </div>
      </div>
      <div class="form-body">
        <hr style="margin-top: 0px;">
        <p class="tip">This table will be available for use in storing, editing, viewing, and transforming your custom business information and data.</p>
        <div class="form-row mg-b-15">
          <div class="form-field">
            <label>Table Name</label>
            <input v-model="table_name"/>
          </div>
          <div class="form-field">
            <label>Table Slug <small>(Read-only)</small></label>
            <input v-model="table_slug" readonly/>
          </div>
          <div class="form-field table-type">
            <label>Table Type</label>
            <select v-model="table_type" disabled>
              <option value="batch">Batch Data</option>
              <option value="stream">Streamed Data</option>
            </select>
          </div>
        </div>
        <template v-if="table_type == 'stream'">
          <div class="form-row mg-b-15">
            <div class="form-field">
              <label>Data Source Type</label>
              <select v-model="table_stream_data_source_type" @change="setPrivateKey" disabled>
                <option value="" disabled>Select Data Source Type</option>
                <option value="iot">IoT Device(s)</option>
              </select>
            </div>
            <div v-if="table_stream_data_source_type == 'iot'" class="form-field private-key">
              <label>Private Key 
                <i v-if="copiedMessage" style="color: green;font-weight:600;">{{ copiedMessage }}</i>
                <i v-else><small>(Click to copy)</small></i>
              </label>
              <input v-model="table_stream_data_source_private_key" @click="copyKey" readonly/>
            </div>
          </div>
        </template>
        <div class="form-row mg-b-0">
          <p class="tip">A primary key field will be automatically generated for this table using the format "{table_slug}_id"</p>
        </div>
        <hr>
        <template v-if="table_type == 'batch'">
          <div class="form-row">
            <div class="form-field add-field mg-b-15">
              <button class="icon" @click="addField">Add Field <i class="las la-plus"></i></button>
            </div>
          </div>
          <div v-for="(field, index) in table_fields" :key="index" class="form-row new-table-field">
            <div class="form-field">
              <button class="icon red" @click="removeField(index)"><i class="las la-ban"></i></button>
            </div>
            <div class="form-field">
              <select v-model="field.table_field_type">
                <option value="string">String</option>
                <option value="int">Integer</option>
                <option value="float">Float</option>
                <option value="boolean">Boolean</option>
                <option value="date">Date</option>
                <option value="datetime">Datetime</option>
                <option value="time">Time</option>
                <option value="json">JSON</option>
              </select>
            </div>
            <div class="form-field">
              <input v-model="field.table_field_slug" placeholder="Field Slug (ex: user_id)"/>
            </div>
            <div class="form-field">
              <input v-model="field.table_field_default_value" placeholder="Default Value (ex: 1)"/>
            </div>
            <div :class="['form-field', 'checkbox', {'is-not-checked': !field.table_field_is_title_field && tableHasTitleField }]">
              <input 
                type="checkbox" 
                v-model="field.table_field_is_title_field" 
                :true-value="1" 
                :false-value="0"
              />
              <label class="checkbox-label"><small>Title Field</small></label>
            </div>
          </div>
        </template>
        <template v-if="table_type == 'stream'">
          <div class="form-row">
            <div class="form-field add-field mg-b-15">
              <button class="icon" @click="addField">Add Field <i class="las la-plus"></i></button>
            </div>
          </div>
          <div v-for="(field, index) in table_fields" :key="index" class="form-row new-table-field">
            <div class="form-field">
              <button class="icon red" @click="removeField(index)"><i class="las la-ban"></i></button>
            </div>
            <div class="form-field">
              <select v-model="field.table_field_type">
                <option value="string">String</option>
                <option value="int">Integer</option>
                <option value="float">Float</option>
                <option value="boolean">Boolean</option>
                <option value="date">Date</option>
                <option value="datetime">Datetime</option>
                <option value="time">Time</option>
                <option value="json">JSON</option>
              </select>
            </div>
            <div class="form-field">
              <select v-model="field.table_field_iot_device_id">
                <option value="" disabled>Select IoT Device</option>
                <option v-for="device in iot_devices" :key="device.sensor_unique_id" :value="device.sensor_unique_id">{{ device.sensor_unique_id }}</option>
              </select>
            </div>
            <div class="form-field">
              <select v-model="field.table_field_iot_device_field">
                <option value="" disabled>Select IoT Device Field</option>
                <option v-for="field in iot_device_fields[field.table_field_iot_device_id]" :key="field.field_name" :value="field.field_name">{{ field.field_name }}</option>
              </select>
            </div>
            <div :class="['form-field', 'checkbox', {'is-not-checked': !field.table_field_is_title_field && tableHasTitleField }]">
              <input 
                type="checkbox" 
                v-model="field.table_field_is_title_field" 
                :true-value="1" 
                :false-value="0"
              />
              <label class="checkbox-label"><small>Title Field</small></label>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    modalData: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      table_name: '',
      table_slug: '',
      table_type: '',
      table_stream_data_source_type: '',
      table_stream_data_source_private_key: '',
      copiedMessage: null,
      iot_devices: [],
      iot_device_fields: [],
      table_fields: [
        { table_field_id: 0, table_field_type: 'string', table_field_iot_device_id: '', table_field_slug: '', table_field_default_value: '', table_field_is_title_field: 0 }
      ]
    };
  },
  watch: {
    table_name(name) {
      this.table_slug = this.tableSlug(name);
    }
  },
  computed: {
    ...mapGetters(['user']),
    tableHasTitleField() {
      return this.table_fields.some(field => field.table_field_is_title_field);
    }
  },
  created() {
    // This is where we populate the fields if a table is passed
    if (this.modalData) {
      console.log("Populating existing table data:", this.modalData); // Debug log
      this.table_name = this.modalData.table_name;
      this.table_slug = this.modalData.table_slug;
      this.table_type = this.modalData.table_type;
      this.table_stream_data_source_type = this.modalData.table_stream_data_source_type;
      this.table_stream_data_source_private_key = this.modalData.table_stream_data_source_private_key;
      // Replace the default empty field array with the existing table fields
      if (this.modalData.table_fields && Array.isArray(this.modalData.table_fields)) {
        this.table_fields = this.modalData.table_fields.map(field => ({
          ...field,
          table_field_is_title_field: field.table_field_is_title_field
        }));
      }

      // Fetch IoT devices
      this.fetchIoTDevices();
    } else {
      console.log("No existing table data provided."); // Debug log
    }
  },
  methods: {
    ...mapActions(['login']),
    async fetchIoTDevices(){
      try {
        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/sensor_controller.php?controller=sensor&method=devices&params=[]', {
          method: 'GET'
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.iot_devices = result.devices;

          // For each field in result.device.sensor_json
          // Add it to the array if it is not the ltoms_auth_token field
          for (const device of this.iot_devices) {
            const sensor_json = JSON.parse(device.sensor_json);

            // Initialize an empty array for each device
            this.iot_device_fields[device.sensor_unique_id] = [];
            for (const field in sensor_json) {
              if (field !== 'ltoms_authorization_key') {
                this.iot_device_fields[device.sensor_unique_id].push({ field_name: field });
              }
            }
          }
        }
      } catch (error) {
        console.log("failed to load tables on dashboard - error: "+error.message);
      }
    },
    copyKey(event){
      const text = event.target.value; // Get the input's value
      navigator.clipboard.writeText(text)
        .then(() => {
          this.copiedMessage = "Copied to clipboard!";
          setTimeout(() => (this.copiedMessage = null), 2000); // Clear message after 2 seconds
        })
        .catch(err => {
          console.error("Failed to copy text:", err);
          this.copiedMessage = "Failed to copy text.";
        });
    },
    tableSlug(name) {
      return name.split(' ').join('_').toLowerCase();
    },
    addField() {
      this.table_fields.push({ table_field_id: 0, table_field_type: 'string', table_field_iot_device_id:'', table_field_slug: '', table_field_default_value: '', table_field_is_title_field: 0 });
    },
    removeField(index) {
      if (this.table_fields.length > 1) {
        this.table_fields.splice(index, 1);
      }
    },
    async save() {
      try {
        const table_field_ids = this.table_fields.map(field => field.table_field_id);

        const payload = {
          table_id: this.modalData ? this.modalData.table_id : null,
          table_name: this.table_name,
          table_slug: this.table_slug,
          table_type: this.table_type,
          table_stream_data_source_type: this.table_stream_data_source_type,
          table_stream_data_source_private_key: this.table_stream_data_source_private_key,
          table_fields: this.table_fields,
          table_field_ids: table_field_ids,
          user: this.user
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=update&params=${JSON.stringify(payload)}`
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.$router.push('/ontology');
          this.closeModal();
          window.location.reload(); // Reload to refresh ontology data for now
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed table update - error: "+error.message);
      }
    },
    closeModal() {
      this.$emit('close-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
  .new-table-field {
    margin-bottom: 10px;
  }

  .form-field.add-field {
    button {
      width: 80px;
      font-size: 12px !important;
      padding: 5px;
    }
  }

  .form-field.private-key {
    flex: 1 !important;

    input {
      font-size: 10px !important;
      line-height: 16px !important;
      cursor: pointer !important;
    }
  }

  .form-field.table-type {
    &, select { cursor: not-allowed !important; }
  }

  .mg-b-15 {
    margin-bottom: 15px;
  }

  .mg-b-0 {
    p {
      margin-bottom: 0 !important;
    }
  }

  .save {
    margin-left: auto;
    margin-right: 15px;
    
    button.cancel {
      margin-right: 15px;
    }
  }

  .form-field.checkbox.is-not-checked {
    opacity: 0.25;
    user-select: none;
    pointer-events: none;
  }
</style>