<template>
  <div view="ontology">
    <div class="view-panel full">
      <div class="view-row">
        <div class="view-column">
          <div class="view-table">
            <div class="view-table-title">
              <div>Manage Ontology Tables
                <span class="info-icon" tool-tip>
                  <div class="tool-tip right">
                    <div class="tool-tip-content">
                      These are your custom Ontology data tables,<br>
                      here you can view their columns and data.
                    </div>
                  </div>
                  <i class="las la-info-circle"></i>
                </span>
              </div>
              <div class="flex-0">
                <router-link to="/create/table">
                  <button class="icon transparent ml-auto" @click="openCreateTableModal"><i class="las la-plus"></i></button>
                </router-link>
              </div>
            </div>
            <div class="view-table-content">
              <div v-for="table in tables" @click="loadDataPreview(table.table_id);selectTable(table.table_slug);" :table-slug="table.table_slug" class="view-table-row">
                <div class="table-data">
                  <div class="table-preview-info">
                    <div v-html="tableIconPreview(table)" class="info-icon" tool-tip></div>
                    <i class="table-slug">{{ table.table_slug }}</i>
                    <div class="columns" tool-tip>
                      ( {{ table.rows }} rows &nbsp;|&nbsp; {{ table.columns + 1 }} <u>column{{ table.columns == 1 ? '': 's' }}</u> )
                      <div class="tool-tip right">
                        <div class="tool-tip-content">
                          <ul>
                            <template v-if="table.table_type=='batch'">
                              <li>{{ table.table_slug }}_id <small>(int)</small></li>
                              <li v-for="field in table.table_fields">
                                {{ field.table_field_slug }} <small>({{ field.table_field_type }})</small>
                              </li>
                            </template>
                            <template v-if="table.table_type=='stream'">
                              <li>{{ table.table_slug }}_id <small>(int)</small></li>
                              <li v-for="field in table.table_fields">
                                <template v-if="field.table_field_iot_device_id == '' || field.table_field_iot_device_field == ''">
                                  <small>NO FIELD CONFIG</small><small> ({{ field.table_field_type }})</small>
                                </template>
                                <template v-else>
                                  {{ field.table_field_iot_device_id }} - {{ field.table_field_iot_device_field }} <small>({{ field.table_field_type }})</small>
                                </template>
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="view-table-actions-menu">
                    <div class="view-table-menu" :table-slug="table.table_slug">
                      <ul>
                        <li class="menu-option" @click="loadDataImport(table.table_slug, table.table_name)">
                          <div class="menu-option-content">
                            <span id="CSVImport">Import</span>
                          </div>
                        </li>
                        <li class="menu-option" @click="openEditTableModal(table)">
                          <div class="menu-option-content">
                            <span id="EditTable">Edit</span>
                          </div>
                        </li>
                        <li class="menu-option" @click="truncateTableData(table.table_slug)">
                          <div class="menu-option-content">
                            <span id="TruncateData">Truncate</span>
                          </div>
                        </li>
                        <li class="menu-option" @click="dropTable(table.table_slug)">
                          <div class="menu-option-content">
                            <span id="DropTable">Drop</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <i class="las la-bars" @click="openViewTableMenu($event)"></i>
                    <i class="las la-times" @click="closeViewTableMenu($event)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-column">
          <div class="view-column-row">
            <Updates />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Updates from '../components/Updates.vue';

const cleanHTML = (html) => html.trim().replace(/\s+/g, ' ')

export default {
  components: {
    Updates
  },
  data() {
    return {
      tables: []
    }
  },
  methods: {
    // Depending on how recent the table was updated, we can determine if the table is online or offline
    tableIconPreview(table) {
      const computeStreamIconColor = () => {
        const now = new Date();
        const lastUpdate = new Date(table.table_last_update_timestamp);

        const diff = now - lastUpdate;
        const diffInSeconds = Math.floor(diff / 1000);
        const diffInMinutes = Math.floor(diff / 1000 / 60);
        const diffInHours = Math.floor(diff / 1000 / 60 / 60);

        if(diffInMinutes < 10) {
          return 'green';
        } else if (diffInMinutes < 30) {
          return 'orange';
        } else if (diffInMinutes < 120) {
          return 'red';
        } else {
          return 'gray';
        }
      };

      // Based on the difference in time, return a verbal description of the status relative to the current time
      const computeStreamStatusText = () => {
        const now = new Date();
        const lastUpdate = new Date(table.table_last_update_timestamp);

        const diff = now - lastUpdate;
        const diffInSeconds = Math.floor(diff / 1000);
        const diffInMinutes = Math.floor(diff / 1000 / 60);
        const diffInHours = Math.floor(diff / 1000 / 60 / 60);

        if(diffInSeconds < 60) {
          return `Last Update <i class="las la-history"></i> <b>${diffInSeconds}s ago</b>`;
        } else if (diffInMinutes < 60) {
          return `Last Update <i class="las la-history"></i> <b>${diffInMinutes}m ago</b>`;
        } else if (diffInHours < 24) {
          return `Last Update <i class="las la-history"></i> <b>${diffInHours}h ago</b>`;
        } else {
          return `Last Update <i class="las la-history"></i> <b>${diffInHours%24} day(s) ago</b>`;
        }
      };

      const color = table.table_type === 'stream' ? computeStreamIconColor() : 'lightblue';
      const icon = table.table_type === 'batch' ? `<i class="lab la-buffer" style="color:${color};"></i>` : `<i class="las la-stream" style="color:${color};"></i>`;
      const streamTableStatus = () => {
        if (table.table_type === 'stream' && color == 'gray') {
          return `Stream table is <b style="color:${color};">Offline</b>`;
        } else if(table.table_type === 'stream'){
          return `Stream table is <b style="color:${color};">Online</b></i> &mdash; `+computeStreamStatusText();
        }

        if (table.table_type === 'batch') {
          return computeStreamStatusText();
        }
      }

      return cleanHTML(`<div class="tool-tip right"><div class="tool-tip-content">${streamTableStatus()}</div></div>${icon}`);
    },
    openViewTableMenu($event) {
      // Get the parent element first
      const parentElement = $event.target.parentElement;
      if (!parentElement) return;
      
      // Find sibling with class .view-table-menu
      let tableMenu = parentElement.querySelector(".view-table-menu");
      
      if (!tableMenu) {
        console.log('No .view-table-menu sibling found');
        return;
      }

      if (!tableMenu.classList.contains("menu-open")) {
        tableMenu.classList.add("menu-open");
      }

      // Hide the open menu icon
      let openIcon = parentElement.querySelector(".las.la-bars");
      if (openIcon) {
        openIcon.style.display = "none";
      }

      // Show the close icon
      let closeIcon = parentElement.querySelector(".las.la-times");
      if (closeIcon) {
        closeIcon.style.display = "block";
      }
    },
    closeViewTableMenu($event) {
      // Get the parent element first
      const parentElement = $event.target.parentElement;
      if (!parentElement) return;
      
      // Find sibling with class .view-table-menu
      let tableMenu = parentElement.querySelector(".view-table-menu");
      
      if (!tableMenu) {
        console.log('No .view-table-menu sibling found');
        return;
      }

      if (tableMenu.classList.contains("menu-open")) {
        tableMenu.classList.remove("menu-open");
      }

      // Show the open icon
      let openIcon = parentElement.querySelector(".las.la-bars");
      if (openIcon) {
        openIcon.style.display = "block";
      }

      // Hide the close icon
      let closeIcon = parentElement.querySelector(".las.la-times");
      if (closeIcon) {
        closeIcon.style.display = "none";
      }
    },
    openCreateTableModal(){
      this.$emit('open-modal', 'create-table');
    },
    openEditTableModal(table){
      this.$emit('open-modal', 'edit-table', table);
    },
    loadDataPreview(table_id){
      this.$emit('load-data-preview', table_id);
    },
    selectTable(table_slug){
      let currentSelection = document.querySelector("[view='ontology'] .view-table-row[table-slug].selected");

      if(currentSelection) {
        currentSelection.classList.remove("selected");
        currentSelection.querySelector(".view-table-menu").style.display = "none";
      }

      let newSelection = document.querySelector("[view='ontology'] .view-table-row[table-slug="+table_slug+"]");

      if(newSelection) {
        newSelection.classList.add("selected");
        newSelection.querySelector(".view-table-menu").style.display = "flex";
      }
    },
    loadDataImport(table_slug, table_name) {
      this.$emit('open-modal', 'data-import', { table_slug, table_name });
    },
    async truncateTableData(table_slug){
      if(!confirm("Are you sure you want to TRUNCATE data for "+table_slug+"? This action is irreversible and the data will be unrecoverable.")){
        return;
      }

      try {
        const payload = {
          table_slug: table_slug,
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=truncate&params=`+JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.dataPreview = result.data;

          try {
            const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
              method: 'GET'
            });

            const result = await response.json();

            if (result.status === 'success') {
              this.tables = result.tables;
              deselectTable();
            } else {
              alert(result.message);
            }
          } catch (error) {
            console.log("failed to load tables on dashboard - error: "+error.message);
          }
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed to load data preview - error: "+error.message);
      }
    },
    async dropTable(table_slug){
      if(!confirm("Are you sure you want to DROP both the table and data for "+table_slug+"? This action is irreversible, the data will be unrecoverable, and you will lose all table fields and structure.")){
        return;
      }

      try {
        const payload = {
          table_slug: table_slug,
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=drop&params=`+JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.dataPreview = result.data;

          try {
            const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
              method: 'GET'
            });

            const result = await response.json();

            if (result.status === 'success') {
              this.tables = result.tables;
              deselectTable();
              this.$emit('clear-data-preview');
            } else {
              alert(result.message);
            }
          } catch (error) {
            console.log("failed to load tables on dashboard - error: "+error.message);
          }
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed to load data preview - error: "+error.message);
      }
    }
  },
  async mounted() {
    try {
      const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
        method: 'GET'
      });

      const result = await response.json();

      if (result.status === 'success') {
        this.tables = result.tables;
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.log("failed to load tables on dashboard - error: "+error.message);
    }
  },
}

function deselectTable(){
  // Deselect current selected table
  let currentSelection = document.querySelector("[view='ontology'] .view-table-row[table-slug].selected");

  if(currentSelection) {
    currentSelection.classList.remove("selected");
    currentSelection.querySelector(".view-table-menu").style.display = "none";
  }
}

</script>

<style lang="scss">
  [view="ontology"] {
    position: relative;

    .view-table-row {
      padding: 5px 10px;
      font-size: 14px;
      flex: 1;
      line-height: 14px;
      box-sizing: border-box !important;
      height: 36px !important; /* Force exact height */
      min-height: 36px !important;
      max-height: 36px !important;
      display: flex;
      align-items: center;
      
      /* Reset any potential margin/border that might affect height */
      margin: 0 !important;
      &:not(:last-of-type) { border-bottom: 1px solid lighten($app-view-form-color, 10%); }
      
      &[table-slug].selected {
        background: linear-gradient(194deg, #293e51, #172631) !important;
        color: #fefefe !important;
        height: 36px !important;
        min-height: 36px !important;
        max-height: 36px !important;
      }

      .view-table-actions-menu {
        @include flex-row;
        align-items: center;
        flex: 0 !important;

        i.las {
          font-size: 22px;
          cursor: pointer;

          &[class*="la-times"] {
            display: none;
          }
        }

        .view-table-menu {
          position: relative;
          display: block;
          background: lighten($app-view-form-color, 10%);
          color: $app-text-color;
          font-size: 12px;
          border-radius: 3px;
          margin-right: 15px;
          width: 0px;
          overflow-x: hidden;
          transition: width 0.15s ease-in-out;

          &.menu-open {
            width: (210px / 3) * 4;
            overflow-x: hidden;
          }

          ul {
            @include flex-row;
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            border-radius: 3px;
  
            li {
              @include flex-row;
  
              &.menu-option {
                padding: 5px 10px;
                
                &:hover {
                  background: darken($app-view-form-color, 5%);
                  cursor: pointer;
                }
  
                .menu-option-content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  white-space: pre;
                  text-align: center;
                  flex: 1;

                  
                  #CSVImport {
                    color: #4dd34d;
                    font-size: 1em;
                  }
                  
                  #EditTable {
                    color: #4495e0;
                    font-size: 1em;
                  }

                  #TruncateData {
                    color: #e9965e;
                    font-size: 1em;
                  }
  
                  #DropTable {
                    color: #d64b4b;
                    font-size: 1em;
                  }
                }
              }
            }
          }
        }
      }
    }

    .table-data {
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;

      .table-preview-info {
        flex: 1;
        white-space: pre;
        display: flex;
        align-items: center;
        font-size: 14px;

        .la-history {
          font-size: 14px !important;
        }

        i.lab, i.las {
          font-size: 22px;
        }

        i.table-slug {
          margin-left: 10px;
          margin-right: 10px;
          font-style: normal;
        }
      }

      .columns {
        position: relative;
        display: inline;
        margin-right: 5px;
        font-size: 12px;
        line-height: 18px;
      }

      .columns {
        cursor: help;
      }
    }

    .view-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 10px;
    }

    .view-column {
      flex: 1;
      text-align: left;

      .view-column-row {
        p {
          font-size: 13px;
        }

        ul {
          list-style-type: square;
          margin: 5px 0;
          padding-left: 30px;
        }
      }
    }
  }
</style>