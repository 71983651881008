<template>
  <div class="intercom-module">
    <div class="room-controls">
      <h2>Available Rooms</h2>
      <ul>
        <li v-for="(room, index) in rooms" :key="index">
          {{ room.name }} ({{ room.participants }} users)
          <button @click="joinRoom(room.name)">Join</button>
        </li>
      </ul>
      <div class="create-room">
        <input v-model="newRoomName" placeholder="Room name" />
        <button @click="createRoom">Create Room</button>
      </div>
    </div>
    <div class="room-content" v-if="currentRoom">
      <h2>Room: {{ currentRoom }}</h2>
      <button @click="leaveRoom">Leave Room</button>
      <div class="videos">
        <div class="video-container">
          <video :ref="setLocalVideo" autoplay muted playsinline controls></video>
          <p>You (Local)</p>
          <div class="debug-info">
            <small>Status: {{ localStreamStatus }}</small>
          </div>
        </div>
        <div v-for="user in participants" :key="user.id" class="video-container">
          <video :ref="el => setRemoteVideo(el, user.id)" autoplay playsinline controls></video>
          <p>{{ user.id }}</p>
          <div class="debug-info">
            <small>Connection: {{ getConnectionStatus(user.id) }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick } from "vue";

export default {
  name: "Intercom",
  setup() {
    const rooms = ref([]);
    const currentRoom = ref(null);
    const newRoomName = ref("");
    const participants = reactive([]);
    const peerConnections = {};
    const localStream = ref(null);
    const localVideo = ref(null);
    const remoteVideos = reactive({});
    const pendingCandidates = {};
    const localStreamStatus = ref('Initializing...');
    const connectionStatuses = reactive({});
    let userId = null;
    let isRoomCreator = false; // Track if this user created the room

    const socket = new WebSocket(`wss://${process.env.VUE_APP_WS_URL}/ws/`);

    const setLocalVideo = (el) => {
      if (el) {
        localVideo.value = el;
        if (localStream.value) {
          localVideo.value.srcObject = localStream.value;
        }
      }
    };
    
    const setRemoteVideo = (el, id) => {
      if (el) {
        remoteVideos[id] = el;
      }
    };

    const updateConnectionStatus = (id, state) => {
      connectionStatuses[id] = state;
    };

    const getConnectionStatus = (id) => {
      const status = connectionStatuses[id];
      switch (status) {
        case 'new':
          return 'Connecting...';
        case 'connecting':
          return 'Establishing connection...';
        case 'connected':
          return 'Connected';
        case 'disconnected':
          return 'Disconnected';
        case 'failed':
          return 'Connection failed';
        case 'closed':
          return 'Connection closed';
        default:
          return 'Not connected';
      }
    };

    socket.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message:', data);

      switch (data.type) {
        case "roomList":
          rooms.value = data.rooms.map((room) => ({
            name: room.name,
            participants: room.participants,
          }));
          break;

        case "roomCreated":
          // Already have isRoomCreator = true when creating room
          break;

        case "roomUsers":
          {
            const filteredUsers = data.users.filter(uid => uid !== userId);
            console.log(`Room users (excluding self): ${filteredUsers.join(', ')}`);
            
            // Remove participants that are no longer in the room
            const currentParticipantIds = participants.map(p => p.id);
            const participantsToRemove = currentParticipantIds.filter(uid => !filteredUsers.includes(uid));
            participantsToRemove.forEach(uid => {
              const index = participants.findIndex(p => p.id === uid);
              if (index !== -1) {
                participants.splice(index, 1);
                closePeerConnection(uid);
                delete connectionStatuses[uid];
              }
            });

            // Add new participants
            for (const uid of filteredUsers) {
              if (!participants.some(p => p.id === uid)) {
                participants.push({ id: uid });
                updateConnectionStatus(uid, 'new');
                await nextTick();
                if (!peerConnections[uid]) {
                  await createPeerConnection(uid);
                  // If we're not the creator, we do NOT start negotiation here.
                  // The creator or existing members will send us offers.
                }
              }
            }
          }
          break;

        case "newParticipant":
          // This event is received by everyone except the new user
          // If we are the room creator, we initiate negotiation by sending offers
          if (isRoomCreator && data.id !== userId) {
            console.log(`New participant joined: ${data.id}`);
            if (!participants.some(p => p.id === data.id)) {
              participants.push({ id: data.id });
              updateConnectionStatus(data.id, 'new');
              await nextTick();
              if (!peerConnections[data.id]) {
                await createPeerConnection(data.id);
              }
              // As room creator, we start negotiation for the new participant
              await startNegotiation(data.id);
            }
          }
          break;

        case "participantLeft":
          console.log(`Participant left: ${data.id}`);
          {
            const index = participants.findIndex((user) => user.id === data.id);
            if (index !== -1) {
              participants.splice(index, 1);
              closePeerConnection(data.id);
              delete connectionStatuses[data.id];
            }
          }
          break;

        case "signal":
          await handleSignal(data.sender, data.sdp, data.candidate);
          break;
      }
    };

    const sendMessage = (message) => {
      const messageWithId = {
        ...message,
        id: userId
      };
      
      if (socket.readyState === WebSocket.OPEN) {
        console.log('Sending message:', messageWithId);
        socket.send(JSON.stringify(messageWithId));
      } else {
        socket.addEventListener("open", () => {
          console.log('Sending message after socket open:', messageWithId);
          socket.send(JSON.stringify(messageWithId));
        }, { once: true });
      }
    };

    const fetchRooms = () => {
      sendMessage({ type: "fetchRooms" });
    };

    const createRoom = async () => {
      if (newRoomName.value) {
        userId = generateId();
        isRoomCreator = true;
        console.log(`Creating room ${newRoomName.value} with ID ${userId}`);
        sendMessage({ 
          type: "createRoom", 
          room: newRoomName.value, 
          id: userId 
        });
        currentRoom.value = newRoomName.value;
        newRoomName.value = "";
      }
    };

    const joinRoom = async (roomName) => {
      userId = generateId();
      isRoomCreator = false;
      console.log(`Joining room ${roomName} with ID ${userId}`);
      sendMessage({ type: "join", room: roomName, id: userId });
      currentRoom.value = roomName;
    };

    const leaveRoom = () => {
      sendMessage({ type: "leave", id: userId });
      currentRoom.value = null;
      participants.splice(0, participants.length);
      Object.keys(peerConnections).forEach(closePeerConnection);
      Object.keys(pendingCandidates).forEach(id => {
        delete pendingCandidates[id];
      });
      Object.keys(connectionStatuses).forEach(id => {
        delete connectionStatuses[id];
      });
      if (localStream.value) {
        localStream.value.getTracks().forEach(track => track.stop());
      }
      userId = null;
      isRoomCreator = false;
    };

    const setupLocalStream = async () => {
      try {
        console.log("Requesting media devices...");
        localStreamStatus.value = 'Requesting media access...';
        
        const constraints = {
          audio: true,
          video: {
            width: { ideal: 320 },
            height: { ideal: 240 },
            frameRate: { ideal: 30 }
          }
        };
        
        localStream.value = await navigator.mediaDevices.getUserMedia(constraints);
        
        console.log("Media access granted:", {
          videoTracks: localStream.value.getVideoTracks().length,
          audioTracks: localStream.value.getAudioTracks().length
        });
        
        localStream.value.getTracks().forEach(track => {
          console.log(`Track ${track.kind} enabled:`, track.enabled);
          console.log(`Track ${track.kind} active:`, track.readyState);
        });

        localStreamStatus.value = 'Local stream acquired';

        if (localVideo.value) {
          localVideo.value.srcObject = localStream.value;
          localStreamStatus.value = 'Local video connected';
        }

        return true;
      } catch (error) {
        console.error("Error accessing media devices:", error);
        localStreamStatus.value = `Error: ${error.message}`;
        return false;
      }
    };

    const createPeerConnection = async (id) => {
      if (peerConnections[id]) {
        console.log(`Using existing peer connection for ${id}`);
        return peerConnections[id];
      }

      console.log(`Creating peer connection for user: ${id}`);
      try {
        const peerConnection = new RTCPeerConnection({
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun1.l.google.com:19302" }
          ],
          iceCandidatePoolSize: 10
        });

        peerConnection.onconnectionstatechange = () => {
          const state = peerConnection.connectionState;
          console.log(`Peer ${id} connection state changed to: ${state}`);
          updateConnectionStatus(id, state);
        };

        if (!pendingCandidates[id]) {
          pendingCandidates[id] = [];
        }

        // Add local tracks
        if (localStream.value) {
          console.log(`Adding local stream to peer ${id}`);
          localStream.value.getTracks().forEach((track) => {
            peerConnection.addTrack(track, localStream.value);
          });
        }

        peerConnection.ontrack = async (event) => {
          console.log(`Received track from peer ${id}:`, event.track.kind);
          if (event.streams && event.streams[0]) {
            await nextTick();
            const videoElement = remoteVideos[id];
            if (videoElement) {
              videoElement.srcObject = event.streams[0];
              console.log(`Remote video for peer ${id} set`);
            } else {
              console.error(`No video element found for peer ${id}`);
            }
          } else {
            console.error(`No streams available in track event for peer ${id}`);
          }
        };

        peerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            console.log(`Sending ICE candidate to peer ${id}`);
            sendMessage({
              type: "signal",
              room: currentRoom.value,
              id: userId,
              payload: { receiver: id, candidate: event.candidate },
            });
          }
        };

        peerConnections[id] = peerConnection;
        return peerConnection;
      } catch (error) {
        console.error(`Error creating peer connection for ${id}:`, error);
        throw error;
      }
    };

    // Only the room creator will initiate negotiation (create offers) to new participants
    const startNegotiation = async (id) => {
      if (!isRoomCreator) return; // Avoid double offers if not creator
      const pc = peerConnections[id];
      if (!pc) return;

      console.log(`Starting negotiation with ${id}`);
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
      sendMessage({
        type: "signal",
        room: currentRoom.value,
        id: userId,
        payload: { receiver: id, sdp: offer },
      });
    };

    const closePeerConnection = (id) => {
      console.log(`Closing peer connection for ${id}`);
      if (peerConnections[id]) {
        const pc = peerConnections[id];
        pc.close();
        delete peerConnections[id];
        delete pendingCandidates[id];
        delete connectionStatuses[id];
      }
    };

    const handleSignal = async (sender, sdp, candidate) => {
      try {
        let peerConnection = peerConnections[sender];
        const isNewConnection = !peerConnection;
        
        if (isNewConnection) {
          console.log(`Creating new peer connection for sender ${sender}`);
          peerConnection = await createPeerConnection(sender);
        }

        if (sdp) {
          console.log(`Processing SDP for peer ${sender}:`, sdp.type);
          await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
          
          // Process any pending candidates
          if (pendingCandidates[sender] && pendingCandidates[sender].length > 0) {
            console.log(`Adding ${pendingCandidates[sender].length} pending candidates for ${sender}`);
            for (const c of pendingCandidates[sender]) {
              await peerConnection.addIceCandidate(new RTCIceCandidate(c));
            }
            pendingCandidates[sender] = [];
          }
          
          if (sdp.type === "offer") {
            console.log(`Creating answer for ${sender}`);
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            sendMessage({
              type: "signal",
              room: currentRoom.value,
              id: userId,
              payload: { receiver: sender, sdp: answer },
            });
          }
        } else if (candidate) {
          if (!peerConnection.remoteDescription) {
            console.log(`Storing candidate for ${sender} until remote description is set`);
            if (!pendingCandidates[sender]) {
              pendingCandidates[sender] = [];
            }
            pendingCandidates[sender].push(candidate);
          } else {
            console.log(`Adding ICE candidate for ${sender}`);
            await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
          }
        }
      } catch (error) {
        console.error(`Error in handleSignal for peer ${sender}:`, error);
        closePeerConnection(sender);
      }
    };

    const generateId = () => Math.random().toString(36).substring(2, 15);

    onMounted(async () => {
      await setupLocalStream();
      // Fetch all rooms after local stream is ready
      fetchRooms();
    });

    return {
      rooms,
      currentRoom,
      newRoomName,
      participants,
      localVideo,
      remoteVideos,
      localStreamStatus,
      getConnectionStatus,
      createRoom,
      joinRoom,
      leaveRoom,
      setLocalVideo,
      setRemoteVideo,
    };
  },
};
</script>

<style>
.intercom-module {
  width: 100%;
  padding: 20px;
}
.room-controls {
  margin-bottom: 20px;
}
.create-room {
  margin-top: 10px;
}
.videos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
video {
  width: 320px;
  height: 240px;
  background: #000;
  border: 1px solid #ccc;
  object-fit: cover;
}
.debug-info {
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
}
</style>
